.imageEmployee {
    width: fit-content;
}

@media (max-width: 767px) {
    .MobileViewEmployee {
        flex-direction: column;
    }

    .imageEmployee {
        margin: auto; /* Center the image horizontally */
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.sideEmployeeTabs{
   
    margin-top: 10px;
    padding:15px;
    border-bottom: 2px solid white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}