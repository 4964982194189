@font-face {
  font-family: "neuelt";
  src: url("./assets/fonts/neuelt.ttf");
}

html {
  font-size: 16px !important;
}

body {
  background-color: #EAF2F6 !important;
  font-family: "neuelt", serif, sans-serif !important;
}

.tab-link.active {
  border-bottom: 2px solid #602ACE;
}

/* styling the whole tables in the project */
Td,
Th {
    text-align: center !important;
}

.start {
    text-align: start !important;
}

/* styling the active icons */
.activeIcon{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.reasonsData:not(:last-child){
  border-bottom: 1px solid #dadada;
}

.deleteBtn {
  width: fit-content !important;
}