.mobileViewCallCenter{
    display: flex;
    gap: 15px;
}

@media(max-width:767px){
    .mobileViewCallCenter{
        flex-direction: column;
    }
} 


  